@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
* {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

.navbar {
  padding: 2px;
  /* border: 1px black solid; */
  float: right;
  max-width: 50%;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
}

.navbar .links {
  /* border: 1px black solid; */
  margin-left: auto;
}

.navbar h1 {
  color: blue;
}

.navbar a {
  color: black;
  text-decoration: none;
  margin: 0px 15px 0px;
}

.navbar a:hover {
  color: blue;
}

.content {
  max-width: 50%;
  margin: 40px auto;
  padding: 20px;
}

.problem-card {
  border: 1px black solid;
  padding: 20px;
  margin: 20px 20px 20px 0px;
  font-size: 30px;
}

/* .problem-card {
  text-decoration: none;
} */

a {
  text-decoration: none;
}

.problem-card:hover {
  box-shadow: 1px 3px 5px blue;
}

.problem-details h2 {
  margin-bottom: 10px;
}

.problem-details div {
  margin-top: 10px;
}

.black {
  color: black;
}

.blue {
  color: blue;
}

.white {
  color: white;
}

.bg-green {
  background: #46db40;
  opacity: 0.5;
}

.bold {
  font-weight: bold;
}

.submit {
  margin: 0 auto;
  max-width: 600px;
}

.submit .submit-form {
  display: grid;
  text-align: left;
}

.submit-form input,
.submit-form textarea {
  margin: 5px 10px 15px 0px;
  padding: 6px 10px;
}

.submit button {
  padding: 5px 10px;
  background-color: #ff004c;
  border-radius: 10px;
  margin: 0 auto;
}

.statement {
  margin: 20px 20px 20px 0px;
}

code {
  font: monospace;
  display: block;
  background-color: #efefef;
  border: 1.5px #dddddd solid;
  color: #a03737;
  display: block;
  padding: 4px;
  margin-bottom: 5px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

code-box {
  display: block;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  padding: 4px;
  margin: 0;
  border: 1.5px #dddddd solid;
}

